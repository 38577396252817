import { Image, StaticImport } from '@graphcommerce/image'
import { Box, BoxProps } from '@mui/material'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import AffirmIcon from './PaymentIcon/Affirm.svg'
import AmexIcon from './PaymentIcon/Amex.svg'
import ApplePayIcon from './PaymentIcon/ApplePay.svg'
import BancontactIcon from './PaymentIcon/Bancontact.svg'
import BitpayIcon from './PaymentIcon/Bitpay.svg'
import CitadeleIcon from './PaymentIcon/Citadele.svg'
import DinersClubIcon from './PaymentIcon/DinersClub.svg'
import DiscoverIcon from './PaymentIcon/Discover.svg'
import FacebookPayIcon from './PaymentIcon/FacebookPay.svg'
import ForbrugsforeningenIcon from './PaymentIcon/Forbrugsforeningen.svg'
import GooglePayIcon from './PaymentIcon/GooglePay.svg'
import InteracIcon from './PaymentIcon/Interac.svg'
import JCBIcon from './PaymentIcon/JCB.svg'
import KlarnaIcon from './PaymentIcon/Klarna.svg'
import LinkIcon from './PaymentIcon/Link.svg'
import MaestroIcon from './PaymentIcon/Maestro.svg'
import MastercardIcon from './PaymentIcon/Mastercard.svg'
import PayPalIcon from './PaymentIcon/PayPal.svg'
import PayoneerIcon from './PaymentIcon/Payoneer.svg'
import PaysafeIcon from './PaymentIcon/Paysafe.svg'
import PoliIcon from './PaymentIcon/Poli.svg'
import SEPAIcon from './PaymentIcon/SEPA.svg'
import SkrillIcon from './PaymentIcon/Skrill.svg'
import SofortIcon from './PaymentIcon/Sofort.svg'
import StripeIcon from './PaymentIcon/Stripe.svg'
import UnionPayIcon from './PaymentIcon/UnionPay.svg'
import VenmoIcon from './PaymentIcon/Venmo.svg'
import VerifoneIcon from './PaymentIcon/Verifone.svg'
import VisaIcon from './PaymentIcon/Visa.svg'
import VisaDebitIcon from './PaymentIcon/VisaDebit.svg'
import WebmoneyIcon from './PaymentIcon/Webmoney.svg'

export type PaymentIconProps = {
  provider:
    | 'affirm'
    | 'amex'
    | 'applepay'
    | 'bancontact'
    | 'bitpay'
    | 'citadele'
    | 'dinersclub'
    | 'discover'
    | 'facebookpay'
    | 'forbrugsforeningen'
    | 'googlepay'
    | 'interac'
    | 'jcb'
    | 'klarna'
    | 'link'
    | 'maestro'
    | 'mastercard'
    | 'payoneer'
    | 'paypal'
    | 'paysafe'
    | 'poli'
    | 'sepa'
    | 'skrill'
    | 'sofort'
    | 'stripe'
    | 'unionpay'
    | 'venmo'
    | 'verifone'
    | 'visa'
    | 'visadebit'
    | 'webmoney'
    | string
} & Pick<BoxProps, 'sx'>

const { classes, selectors } = extendableComponent('Breadcrumbs', ['paymentIcon'] as const)

export function PaymentIcon(props: PaymentIconProps) {
  const { provider, sx = [] } = props

  let icon: string | StaticImport | null = null

  switch (provider.toLowerCase()) {
    case 'affirm':
      icon = AffirmIcon
      break
    case 'amex':
      icon = AmexIcon
      break
    case 'applepay':
      icon = ApplePayIcon
      break
    case 'bancontact':
      icon = BancontactIcon
      break
    case 'bitpay':
      icon = BitpayIcon
      break
    case 'citadele':
      icon = CitadeleIcon
      break
    case 'dinersclub':
      icon = DinersClubIcon
      break
    case 'discover':
      icon = DiscoverIcon
      break
    case 'facebookpay':
      icon = FacebookPayIcon
      break
    case 'forbrugsforeningen':
      icon = ForbrugsforeningenIcon
      break
    case 'googlepay':
      icon = GooglePayIcon
      break
    case 'interac':
      icon = InteracIcon
      break
    case 'jcb':
      icon = JCBIcon
      break
    case 'klarna':
      icon = KlarnaIcon
      break
    case 'link':
      icon = LinkIcon
      break
    case 'maestro':
      icon = MaestroIcon
      break
    case 'mastercard':
      icon = MastercardIcon
      break
    case 'payoneer':
      icon = PayoneerIcon
      break
    case 'paypal':
      icon = PayPalIcon
      break
    case 'paysafe':
      icon = PaysafeIcon
      break
    case 'poli':
      icon = PoliIcon
      break
    case 'sepa':
      icon = SEPAIcon
      break
    case 'skrill':
      icon = SkrillIcon
      break
    case 'sofort':
      icon = SofortIcon
      break
    case 'stripe':
      icon = StripeIcon
      break
    case 'unionpay':
      icon = UnionPayIcon
      break
    case 'venmo':
      icon = VenmoIcon
      break
    case 'verifone':
      icon = VerifoneIcon
      break
    case 'visa':
      icon = VisaIcon
      break
    case 'visadebit':
      icon = VisaDebitIcon
      break
    case 'webmoney':
      icon = WebmoneyIcon
      break
  }

  if (!icon) {
    return null
  }

  return (
    <Box
      className={classes.paymentIcon}
      sx={[
        {
          display: 'flex',
          '& svg': {
            display: 'block',
            width: 'auto',
            height: '100%',
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Image src={icon} unoptimized />
    </Box>
  )
}

PaymentIcon.selectors = selectors
