import { CraftSettingEntryWithValueFragment } from '../../graphql/CraftSettingEntryWithValueFragment.gql'
import { CraftSettingEntryWithValueHtmlFragment } from '../../graphql/CraftSettingEntryWithValueHtmlFragment.gql'
import { CraftSettingEntryWithAssetFragment } from '../../graphql/CraftSettingEntryWithAssetFragment.gql'
import striptags from 'striptags'

export function isValidSetting(
  setting:
    | CraftSettingEntryWithValueFragment
    | CraftSettingEntryWithValueHtmlFragment
    | CraftSettingEntryWithAssetFragment
    | Record<string, never>
    | unknown,
): setting is
  | CraftSettingEntryWithValueFragment
  | CraftSettingEntryWithValueHtmlFragment
  | CraftSettingEntryWithAssetFragment {
  return (
    !!setting &&
    typeof setting === 'object' &&
    ('value' in setting ||
      // Used as alias in fragments
      'settingValue' in setting ||
      'asset' in setting ||
      // Used as alias in fragments
      'settingAsset' in setting)
  )
}

export function isValidSettingWithValue(
  setting: CraftSettingEntryWithValueFragment | Record<string, never> | unknown,
): setting is CraftSettingEntryWithValueFragment {
  return isValidSetting(setting) && ('value' in setting || 'settingValue' in setting)
}

export function isValidSettingWithValueHtml(
  setting: CraftSettingEntryWithValueHtmlFragment | Record<string, never> | unknown,
): setting is CraftSettingEntryWithValueHtmlFragment {
  return (
    isValidSetting(setting) &&
    ('value' in setting || 'settingValue' in setting || 'valueFallback' in setting)
  )
}

export function isValidSettingWithAsset(
  setting: CraftSettingEntryWithAssetFragment | Record<string, never> | unknown,
): setting is CraftSettingEntryWithAssetFragment {
  return isValidSetting(setting) && ('asset' in setting || 'settingAsset' in setting)
}

export function findSettingValue(setting?: CraftSettingEntryWithValueHtmlFragment | null) {
  if (!setting) {
    return ''
  }

  if (setting.value && striptags(setting.value).trim().length > 0) {
    return setting.value
  }

  if (setting.valueFallback) {
    return setting.valueFallback
  }

  return ''
}
