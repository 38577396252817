import { ContentEdgeCraftQuery } from '../../graphql/ContentEdgeCraft.gql'
import { ContentEdgeMagentoQuery } from '../../graphql/ContentEdgeMagento.gql'
import { useQuery } from '@tanstack/react-query'

type ContentEdge = ContentEdgeCraftQuery & ContentEdgeMagentoQuery

export default function useContentEdge() {
  const isDev =
    process.env.GC_CANONICAL_BASE_URL?.includes('localhost') ||
    process.env.GC_CANONICAL_BASE_URL?.includes('vercel.app')

  return useQuery({
    queryKey: ['contentEdge'],
    cacheTime: 1000 * 60 * 60 * 12,
    staleTime: isDev ? 1000 * 30 : 1000 * 60 * 5,
    refetchOnWindowFocus: true,
    queryFn: async () => {
      const contentResult = await (await fetch('/api/content-edge')).json()

      if ('result' in contentResult && contentResult.result !== null) {
        return contentResult.result as ContentEdge
      }

      return null
    },
  })
}
