import { Box, Container, Link, Divider, Typography } from '@mui/material'
import { FooterQueryFragment } from './FooterQueryFragment.gql'
import { Trans } from '@lingui/react'
import { Image } from '@graphcommerce/image'
import { Logo } from './Logo'
import { PaymentIcon } from './PaymentIcon'
import { SocialIcon } from './SocialIcon'
import {
  isValidSetting,
  isValidSettingWithAsset,
  isValidSettingWithValue,
} from '../../lib/craft/settings'
import { CraftSettingEntryWithValueFragment } from '../../graphql/CraftSettingEntryWithValueFragment.gql'
import { CraftMenuEntryFragment } from '../../graphql/CraftMenuEntryFragment.gql'
import useContentEdge from '../../hooks/ContentEdge/useContentEdge'
import { Signup } from './Footer/Signup'

export type FooterProps = FooterQueryFragment

type SocialTuple = [string, CraftSettingEntryWithValueFragment]
type MenuTuple = [string, CraftMenuEntryFragment]

export function Footer(props: FooterProps) {
  const { data: edgeContent } = useContentEdge()

  const {
    footerMenuOne,
    footerMenuTwo,
    footerMenuThree,
    footerSocialFacebookURL,
    footerSocialLinkedInURL,
    footerSocialPinterestURL,
    footerSocialTwitterURL,
    footerSocialInstagramURL,
    footerAddress,
    footerOpeningHours,
    footerContactPhoto,
  } = edgeContent || props

  const footerSocials: SocialTuple[] = [
    ['facebook', footerSocialFacebookURL] as SocialTuple,
    ['pinterest', footerSocialPinterestURL] as SocialTuple,
    ['instagram', footerSocialInstagramURL] as SocialTuple,
    ['twitter', footerSocialTwitterURL] as SocialTuple,
    ['linkedin', footerSocialLinkedInURL] as SocialTuple,
  ]
    .map(([key, setting]) => (isValidSetting(setting) ? [key, setting] : null))
    .filter(Boolean) as unknown as SocialTuple[]

  const footerMenus: MenuTuple[] = [
    ['menu1', footerMenuOne] as MenuTuple,
    ['menu2', footerMenuTwo] as MenuTuple,
    ['menu3', footerMenuThree] as MenuTuple,
  ]

  return (
    <>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.contrastText,
          marginTop: theme.spacings.md,

          [theme.breakpoints.up('md')]: {
            paddingLeft: theme.page.horizontal,
            paddingRight: theme.page.horizontal,
          },
        })}
      >
        <Container>
          <Box
            sx={(theme) => ({
              padding: `${theme.spacings.sm} 0`,

              [theme.breakpoints.down('md')]: {
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacings.xs,
              },

              [theme.breakpoints.up('md')]: {
                display: 'grid',
                gridTemplateColumns: '10fr 17fr',
                gridTemplateRows: 'repeat(2, auto)',
                gridTemplateAreas: `
                  'title text'
                  'title signup'
                `,
                rowGap: theme.spacings.xxxs,
                columnGap: theme.spacings.md,
              },
            })}
          >
            <Typography
              variant='h2'
              component='div'
              sx={{ gridArea: 'title', margin: '-0.25em 0', alignSelf: 'center' }}
            >
              <Trans
                id='Sign up to hear about our <0>latest news</0> and offers'
                components={{ 0: <em /> }}
              />
            </Typography>
            <Typography variant='caption' component='div' sx={{ gridArea: 'text', maxWidth: 700 }}>
              <Trans id='By signing up you agree to receive weekly email updates including special offers, free art fair tickets, free private views and the latest art exhibition news. If you would like to you can unsubscribe at any time.' />
            </Typography>
            <Box sx={{ gridArea: 'signup' }}>
              <Signup />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,

          [theme.breakpoints.up('md')]: {
            paddingLeft: theme.page.horizontal,
            paddingRight: theme.page.horizontal,
          },
        })}
      >
        <Container>
          <Box
            sx={(theme) => ({
              display: 'grid',
              justifyContent: 'space-between',
              padding: `${theme.spacings.lg} 0`,
              '--logo-color': '#fff',

              [theme.breakpoints.down('md')]: {
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridTemplateRows: 'repeat(9, auto)',
                gridTemplateAreas: `
                  "logo      logo"
                  "social    social"
                  "hr1       hr1"
                  "menu1     menu2"
                  "menu3     ."
                  "contact   contact"
                  "hr2       hr2"
                  "partners  partners"
                  "copyright copyright"
                `,
                rowGap: theme.spacings.md,
              },

              [theme.breakpoints.up('md')]: {
                gridTemplateColumns: 'repeat(4, 1fr)',
                gridTemplateRows: 'repeat(5, auto)',
                gridTemplateAreas: `
                  "logo     logo     social    social"
                  "hr1      hr1      hr1       hr1"
                  "menu1    menu2    menu3     contact"
                  "hr2      hr2      hr2       hr2"
                  "partners partners copyright copyright"
                `,
              },
            })}
          >
            {[1, 2].map((hrIndex) => (
              <Divider
                key={hrIndex}
                sx={(theme) => ({
                  gridArea: `hr${hrIndex}`,
                  borderColor: theme.palette.secondary.light,
                  margin: `${theme.spacings.md} 0`,
                })}
              />
            ))}
            <Logo
              sx={{
                gridArea: 'logo',
                filter: 'invert(1)',
                maxWidth: '90%',
              }}
            />
            {footerMenus.map(([menuId, menu]) => (
              <Box
                key={menuId}
                sx={(theme) => ({
                  gridArea: menuId,
                  display: 'flex',
                  flexDirection: 'column',

                  '& a': {
                    color: 'inherit',
                    textDecoration: 'none',
                    '&:first-child': {
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      marginBottom: theme.spacings.xs,
                    },
                  },
                })}
              >
                {menu?.menuItems.map(
                  (item) =>
                    item?.url && (
                      <Link key={item.title} href={item.url}>
                        {item.title}
                      </Link>
                    ),
                )}
              </Box>
            ))}
            <Box
              sx={(theme) => ({
                gridArea: 'contact',

                [theme.breakpoints.down('md')]: {
                  maxWidth: '300px',
                },
              })}
            >
              {isValidSettingWithAsset(footerContactPhoto) && footerContactPhoto.asset[0]?.url && (
                <Image
                  src={footerContactPhoto.asset[0]?.url}
                  layout='fill'
                  unoptimized
                  sx={{ width: '100%', height: 'auto' }}
                  sizes='400px'
                />
              )}
              {isValidSettingWithValue(footerAddress) && (
                <Box component='address' sx={{ fontStyle: 'normal' }}>
                  <p>{footerAddress.value}</p>
                </Box>
              )}
              {isValidSettingWithValue(footerOpeningHours) && (
                <p id='footer-opening-times'>
                  <Trans id='Opening Hours:' />
                  <br />
                  {footerOpeningHours.value}
                </p>
              )}
            </Box>
            <Box
              sx={(theme) => ({
                display: 'grid',
                gridArea: 'social',
                justifyContent: 'center',
                alignItems: 'center',
                gridAutoFlow: 'column',

                [theme.breakpoints.down('md')]: {
                  gap: `0 ${theme.spacings.xs}`,
                },

                [theme.breakpoints.up('md')]: {
                  justifyContent: 'end',
                  gap: `0 ${theme.spacings.sm}`,
                },
              })}
            >
              {footerSocials.map(([key, setting]) => (
                <SocialIcon
                  key={key}
                  network={key}
                  url={setting.value}
                  sx={{
                    height: '25px',
                    filter: 'invert(1)',
                  }}
                />
              ))}
            </Box>
            <Box
              sx={(theme) => ({
                gridArea: 'partners',
                display: 'flex',
                alignItems: 'center',
                gap: {
                  xs: `0 ${theme.spacings.xs}`,
                  md: `0 ${theme.spacings.sm}`,
                },
              })}
            >
              <Image
                src='/images/logos/ownart.png'
                unoptimized
                layout='fill'
                sx={{ width: 'auto', height: '65px' }}
              />
              <Image
                src='/images/logos/feefo.png'
                unoptimized
                layout='fill'
                sx={{ width: 'auto', height: '25px' }}
              />
            </Box>
            <Box
              sx={(theme) => ({
                gridArea: 'copyright',
                justifySelf: 'end',
                alignSelf: 'end',
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacings.xs,
              })}
            >
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  justifyContent: 'end',
                  gap: {
                    xs: `0 ${theme.spacings.xxs}`,
                    md: `0 ${theme.spacings.xs}`,
                  },
                })}
              >
                {'Visa Debit, Visa, Mastercard, Amex, Discover, Diners Club, Apple Pay, Link, JCB'
                  .split(',')
                  .map((provider) => (
                    <PaymentIcon
                      key={provider}
                      provider={provider.trim().toLowerCase().replace(/ /g, '')}
                    />
                  ))}
              </Box>
              <Box
                sx={{
                  textAlign: 'right',
                }}
              >
                <Trans id='&copy; Copyright 2020. All Rights Reserved.' />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  )
}
