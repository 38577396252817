import { Button, TextField } from '@mui/material'
import { Trans } from '@lingui/react'
import { Form } from '@graphcommerce/next-ui'
import { useMailChimpForm, useFormFields } from 'use-mailchimp-form'

export function Signup() {
  const { handleSubmit, error, success, message } = useMailChimpForm(
    'https://wychwoodart.us8.list-manage.com/subscribe/post?u=f502c40b847b6f9a5aeafcb90&amp;id=960a406120&amp;v_id=3349&amp;f_id=008e68e0f0',
  )

  const { fields, handleFieldChange } = useFormFields({
    EMAIL: '',
  })

  return (
    <Form
      sx={{ display: 'flex', alignItems: 'stretch', padding: 0 }}
      onSubmit={(event) => {
        event.preventDefault()
        handleSubmit(fields)
      }}
    >
      {error && message}
      {success && <Trans id='Thanks for signing up' />}
      {!success && (
        <>
          <TextField
            variant='outlined'
            color='darkContrast'
            sx={(theme) => ({
              ...theme.typography.body2,
              flexGrow: 1,
              height: '100%',
              verticalAlign: 'unset',
              borderRight: 'none',

              '.MuiOutlinedInput-input': {
                height: '100%',
                padding: `${theme.spacings.xxxs} ${theme.spacings.xxs}`,
              },
            })}
            placeholder='email address'
            id='EMAIL'
            type='email'
            value={fields.EMAIL}
            onChange={handleFieldChange}
          />
          <Button
            type='submit'
            variant='contained'
            color='darkContrast'
            sx={(theme) => ({
              ...theme.typography.body2,
              textTransform: 'uppercase',
              padding: `${theme.spacings.xxxs} ${theme.spacings.xxs}`,
            })}
          >
            <Trans id='Sign Up' />
          </Button>
        </>
      )}
    </Form>
  )
}
