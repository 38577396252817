import { Box, BoxProps } from '@mui/material'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import FacebookIcon from './SocialIcon/Facebook.svg'
import LinkedInIcon from './SocialIcon/LinkedIn.svg'
import InstagramIcon from './SocialIcon/Instagram.svg'
import TwitterIcon from './SocialIcon/Twitter.svg'
import PinterestIcon from './SocialIcon/Pinterest.svg'
import { Image, StaticImport } from '@graphcommerce/image'

export type SocialIconProps = {
  network: 'facebook' | 'linkedin' | 'instagram' | 'twitter' | 'pinterest' | string
  url: string | null | undefined
} & Pick<BoxProps, 'sx'>

const { classes, selectors } = extendableComponent('Breadcrumbs', ['socialIcon'] as const)

export function SocialIcon(props: SocialIconProps) {
  const { network, url, sx = [] } = props

  if (!url) {
    return null
  }

  let icon: string | StaticImport | null = null

  switch (network) {
    case 'facebook':
      icon = FacebookIcon
      break
    case 'linkedin':
      icon = LinkedInIcon
      break
    case 'instagram':
      icon = InstagramIcon
      break
    case 'twitter':
      icon = TwitterIcon
      break
    case 'pinterest':
      icon = PinterestIcon
      break
  }

  if (!icon) {
    return null
  }

  return (
    <Box
      className={classes.socialIcon}
      sx={[
        {
          display: 'flex',
          '& img': {
            display: 'block',
            width: 'auto',
            height: '100%',
            fill: '#fff',
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <a href={url} target='_blank' rel='noreferrer'>
        <Image src={icon} unoptimized />
      </a>
    </Box>
  )
}

SocialIcon.selectors = selectors
