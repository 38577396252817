import { Logo as LogoBase } from '@graphcommerce/next-ui'
import WychwoodLogo from './wychwood.svg'
import WychwoodLogoSq from './wychwood-sq.svg'
import { BoxProps } from '@mui/material'

export type LogoProps = Pick<BoxProps, 'sx'>

export function Logo({ sx = [] }: LogoProps) {
  return (
    <>
      <LogoBase
        sx={[
          (theme) => ({
            width: 'auto',

            [theme.breakpoints.down('lg')]: {
              marginTop: theme.spacings.xxxs,
              marginBottom: theme.spacings.xxxs,
            },

            [theme.breakpoints.down('md')]: {
              paddingLeft: '56px',
              paddingRight: '56px',
            },

            [theme.breakpoints.between('md', 'mdlg')]: {
              maxWidth: '320px',
            },

            [theme.breakpoints.down('md')]: {
              display: 'none',
            },

            '& .GcLogo-logo': {
              display: 'block',
              width: 'auto',
              maxWidth: '100%',
            },
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        image={{ alt: 'Wychwood Art Logo', src: WychwoodLogo, unoptimized: true }}
      />
      <LogoBase
        sx={[
          (theme) => ({
            width: 'auto',
            maxWidth: '230px',
            height: 'auto',

            [theme.breakpoints.up('md')]: {
              display: 'none',
            },

            '& .GcLogo-parent': {
              height: 'auto',
            },

            '& .GcLogo-logo': {
              display: 'block',
              height: 'auto',
              width: '100%',
            },
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        image={{ alt: 'Wychwood Art Logo', src: WychwoodLogoSq, unoptimized: true }}
      />
    </>
  )
}
