import { useScrollOffset } from '@graphcommerce/framer-next-pages'
import { Box, SxProps, Theme } from '@mui/material'
import { useTransform, useScroll } from 'framer-motion'
import { LayoutProvider } from '@graphcommerce/next-ui/Layout/components/LayoutProvider'
import { extendableComponent } from '@graphcommerce/next-ui/Styles'
import { useFabSize } from '@graphcommerce/next-ui/Theme'
import { useMeasure } from 'react-use'
import { CSSProperties } from 'react'
import { Sidebar as FeefoSidebar } from '../Feefo/Sidebar'

export type LayoutDefaultProps = {
  className?: string
  beforeHeader?: React.ReactNode
  header: React.ReactNode
  footer: React.ReactNode
  menuFab?: React.ReactNode
  cartFab?: React.ReactNode
  children?: React.ReactNode
  noSticky?: boolean
  sx?: SxProps<Theme>
} & OwnerState

type OwnerState = {
  noSticky?: boolean
}
const parts = ['root', 'fabs', 'header', 'children', 'footer'] as const
const { withState } = extendableComponent<OwnerState, 'LayoutDefault', typeof parts>(
  'LayoutDefault',
  parts,
)

export function LayoutDefault(props: LayoutDefaultProps) {
  const {
    children,
    header,
    beforeHeader,
    footer,
    menuFab,
    cartFab,
    noSticky,
    className,
    sx = [],
  } = props

  const [headerRef, { height }] = useMeasure()

  const scrollWithOffset = useTransform(
    [useScroll().scrollY, useScrollOffset()],
    ([y, offset]: number[]) => y + offset,
  )

  const classes = withState({ noSticky })

  const fabIconSize = useFabSize('responsive')

  return (
    <Box
      className={`${classes.root} ${className ?? ''}`}
      sx={[
        (theme) => ({
          minHeight: '100vh',
          '@supports (-webkit-touch-callout: none)': {
            minHeight: '-webkit-fill-available',
          },
          display: 'grid',
          gridTemplateRows: `auto auto 1fr auto`,
          gridTemplateColumns: '100%',
          background: theme.palette.background.default,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      style={{ '--header-height': `${height}px` } as CSSProperties}
    >
      <LayoutProvider scroll={scrollWithOffset}>
        {beforeHeader}
        <Box
          component='header'
          className={classes.header}
          ref={headerRef}
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: theme.appShell.headerHeightSm,
            pointerEvents: 'none',
            position: 'relative',
            zIndex: 1,

            '& > *': {
              pointerEvents: 'all',
            },

            [theme.breakpoints.down('md')]: {
              order: 2,
            },

            [theme.breakpoints.up('md')]: {
              background: 'white',
              height: theme.appShell.headerHeightMd,
              padding: `0 ${theme.page.horizontal} 0`,
              position: 'sticky',
              top: 0,
              zIndex: 1100,
              display: 'flex',
              justifyContent: 'left',
              width: '100%',
            },

            '&.noSticky': {
              position: 'static',
            },
          })}
        >
          {header}
        </Box>
        {(menuFab || cartFab) && (
          <Box
            className={classes.fabs}
            sx={(theme) => ({
              position: 'sticky',
              display: 'flex',
              justifyContent: 'space-between',
              zIndex: 'speedDial',
              top: 10,
              padding: `${theme.spacings.xxxs} 10px ${theme.spacings.xxxs}`,
              pointerEvents: 'none',
              order: 1,

              '& > *': {
                pointerEvents: 'all',
              },

              '.MuiFab-root': {
                '&:hover, &:focus': {
                  background: theme.palette.background.brand
                },
              },

              [theme.breakpoints.up('md')]: {
                display: 'none',
              },
            })}
          >
            {menuFab}
            {cartFab}
          </Box>
        )}
        <Box
          className={classes.children}
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              order: 3,
            },
          })}
        >
          {children}
        </Box>
        <Box
          className={classes.footer}
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              order: 4,
            },
          })}
        >
          {footer}
        </Box>
        <FeefoSidebar />
      </LayoutProvider>
    </Box>
  )
}
